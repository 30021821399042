/* src/components/AddCustomer/AddCustomer.module.css */
.addCustomer {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .addCustomer form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  
  .addCustomer label {
    margin-top: 10px;
    margin-bottom: 5px;
  }
  
  .addCustomer input,
  .addCustomer textarea,
  .addCustomer select {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
  }
  
  .addCustomer textarea {
    resize: vertical;
    min-height: 100px;
  }
  
  .addCustomer button {
    margin-top: 10px;
    padding: 5px 10px;
    cursor: pointer;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 3px;
  }
  
  .addCustomer button:hover {
    background-color: #0056b3;
  }
  