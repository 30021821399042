/* src/components/Offers/AddOffer.module.css */
.addOffer {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .addOffer h2 {
    margin-bottom: 20px;
  }
  
  .addOffer form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .addOffer input,
  .addOffer select,
  .addOffer textarea {
    margin-bottom: 10px;
    padding: 5px;
  }


  .addOffer textarea {
    min-height: 100px
  }
  
  .addOffer button {
    padding: 5px 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .addOffer button:hover {
    background-color: #0056b3;
  }
  