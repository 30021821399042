/* src/components/AddPartner/AddPartner.module.css */
.addPartner {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.addPartner form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.addPartner label {
  margin-top: 10px;
  margin-bottom: 5px;
}

.addPartner input,
.addPartner textarea,
.addPartner select {
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
}

.addPartner textarea {
  resize: vertical;
  min-height: 100px;
}

.addPartner button {
  margin-top: 10px;
  padding: 5px 10px;
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 3px;
}

.addPartner button:hover {
  background-color: #0056b3;
}
