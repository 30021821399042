.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.h1 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    color: #2196f3;
}

.label {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    color: #333;
}

.inputEmail, .inputPassword {
    padding: 0.5rem;
    border: 0.1rem solid rgba(0, 0, 0, 0.2);
    border-radius: 0.25rem;
    margin-top: 0.25rem;
    font-size: 1.2rem;
    color: #333;
}

.buttonSubmit {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.25rem;
    background-color: #2196f3;
    color: #fff;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

.buttonSubmit:hover {
    background-color: #0d8ae8;
}
  