body {
    --black: #000000;
    --ash-black: #222;
    --white: #fafafa;
    --sky: #00ccff;
    --green: #22dddd;
    --blue: #1300ff;
    --dusk: #6600ff;
    --purple: #9900ff;
    --pink: #ff0066;
    --red: #fe0222;
    --orange: #fd7702;
    --yellow: #ffbb00;
  
    --accent: var(--orange);
  
    margin: 0;
    padding: 0;
    background-color: var(--background);
  }
  
  .box {
    width: 200px;
    height: 80px;
    border-radius: 50px;
    background: var(--ash-black);
    cursor: pointer;
    color: var(--white);
    font-weight: 700;
    border: none;
  }
  