/* src/components/Customers/CustomerList/CustomerList.module.css */
.customerList {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .customerList h2 {
    margin-bottom: 20px;
  }
  
  .customerList table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .customerList th,
  .customerList td {
    padding: 10px;
    border: 1px solid #ccc;
  }
  
  .customerList thead {
    background-color: #007bff;
    color: #fff;
  }
  
  .customerList tbody tr:nth-child(odd) {
    background-color: #f8f8f8;
  }
  
  .customerList tbody tr:hover {
    background-color: #e8e8e8;
  }
  
  .customerList a {
    text-decoration: none;
  }
  
  .customerList a:hover {
    text-decoration: underline;
  }
  
  .editLink {
    color: #007bff;
    text-decoration: none;
  }
  
  .editLink:hover {
    text-decoration: underline;
  }
  
  .addCustomerLink {
    display: inline-block;
    margin-top: 20px;
    padding: 5px 10px;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 3px;
  }
  
  .addCustomerLink:hover {
    background-color: #0056b3;
  }
  