/* src/components/Customers/MetricsList/MetricsList.module.css */
.metricsList {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .metricsList h2 {
    margin-bottom: 20px;
  }
  
  .metricsList table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .metricsList th,
  .metricsList td {
    padding: 10px;
    border: 1px solid #ccc;
  }
  
  .metricsList thead {
    background-color: #007bff;
    color: #fff;
  }
  
  .metricsList tbody tr:nth-child(odd) {
    background-color: #f8f8f8;
  }
  
  .metricsList tbody tr:hover {
    background-color: #e8e8e8;
  }
  
  .metricsList a {
    text-decoration: none;
  }
  
  .metricsList a:hover {
    text-decoration: underline;
  }
  
  .editLink {
    color: #007bff;
    text-decoration: none;
  }
  
  .editLink:hover {
    text-decoration: underline;
  }
  
  .addCustomerLink {
    display: inline-block;
    margin-top: 20px;
    padding: 5px 10px;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 3px;
  }
  
  .addCustomerLink:hover {
    background-color: #0056b3;
  }
  