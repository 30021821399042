/* src/components/EditPartner/EditPartner.module.css */
.editPartner {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .editPartner form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  
  .editPartner label {
    margin-top: 10px;
    margin-bottom: 5px;
  }
  
  .editPartner input,
  .editPartner textarea,
  .editPartner select {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
  }
  
  .editPartner textarea {
    resize: vertical;
    min-height: 100px;
  }
  
  .editPartner button {
    margin-top: 10px;
    padding: 5px 10px;
    cursor: pointer;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 3px;
  }
  
  .editPartner button:hover {
    background-color: #0056b3;
  }
  