.modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modalContent {
    background-color: white;
    padding: 1.5rem;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .success {
    color: #3c763d;
  }
  
  .error {
    background-color: #f2dede;
    color: #a94442;
  }
  
  .closeButton {
    align-self: flex-end;
    border: none;
    background-color: transparent;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .message {
    margin: 0.5rem 0;
    font-size: 1.25rem;
    text-align: center;
  }
  