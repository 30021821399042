/* src/components/PartnerList/PartnerList.module.css */
.partnerList {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .partnerList h2 {
    margin-bottom: 20px;
  }
  
  .partnerList table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .partnerList th,
  .partnerList td {
    padding: 10px;
    border: 1px solid #ccc;
  }
  
  .partnerList thead {
    background-color: #007bff;
    color: #fff;
  }
  
  .partnerList tbody tr:nth-child(odd) {
    background-color: #f8f8f8;
  }
  
  .partnerList tbody tr:hover {
    background-color: #e8e8e8;
  }
  
  .partnerList a {
    text-decoration: none;
  }
  
  .partnerList a:hover {
    text-decoration: underline;
  }
  
  .editLink {
    color: #007bff;
    text-decoration: none;
  }
  
  .editLink:hover {
    text-decoration: underline;
  }
  
  .addPartnerLink {
    display: inline-block;
    margin-top: 20px;
    padding: 5px 10px;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 3px;
  }
  
  .addPartnerLink:hover {
    background-color: #0056b3;
  }
  